@import '../../styles/variables';

.container {
  width: 100vw;
  height: 100%;
  overflow-y: auto;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  @include scrollbar;
  z-index: 3;
  @media only screen and (min-width: $md-screen) {
    width: calc(100vw - 320px);
  }
  h2 {
    @include title-1;
    padding: 0px 20px;
    margin: 0px;
    @media only screen and (min-width: $md-screen) {
      display: none;
    }
  }
  .favoris {
    margin: 20px;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 10px;
    @media only screen and (min-width: $sm-screen) {
      grid-template-columns: repeat(3,1fr);
    }
    @media only screen and (min-width: $md-screen) {
      grid-template-columns: repeat(3,1fr);
      margin: 20px 20px 30px 0px;
    }
    @media only screen and (min-width: $lg-screen) {
      grid-template-columns: repeat(4,1fr);
    }
  }
  .tool, .news {
    display: flex;
    margin: 20px 0px 0px 20px;
    flex-direction: column;
    @media only screen and (min-width: $md-screen) {
      margin: 20px 0px 30px 0px;
    }
  }
}