@import '../../styles/variables';

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 100px;
  @media only screen and (min-width: $md-screen) {
    width: calc(100% - 320px);
    margin-left: 320px;
  }
  &.is-notifs {
    pointer-events: none;
  }
  header {
    // background: linear-gradient(to bottom, $bg-color 80%, transparent 100%);
    pointer-events: none;
    top: 0px;
    left: 0px;
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: $margin-md;
    .logo {
      width: 258px;
      z-index: 12;
      margin-right: 20px;
      img {
        width: 100%;
      }
    }
  }
  h1 {
    @include title-1;
    margin: 2px 0px;
    display: none;
    @media only screen and (min-width: $md-screen) {
      display: block;
      position: absolute;
      transform: translateY(-65px);
      z-index: 4;
    } 
  }
  h2 {
    @include title-2;
  }
  .nav {
    z-index: 2;
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 5px;
    @media only screen and (min-width: $md-screen) {
      position: relative;
    }
    ul {
      width: calc(100% - 40px);
      display: flex;
      justify-content: flex-start;
      background: rgba(210, 207, 227, 0.24);
      border-radius: 5px;
      padding: 0px;
      overflow-x: scroll;
      -ms-overflow-style: none; /* for Internet Explorer, Edge */
      scrollbar-width: none; /* for Firefox */
      &::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
      }
      @media only screen and (min-width: $md-screen) {
        margin: 8px 0px;
        overflow-x: hidden;
        width: 100%;
        justify-content: flex-start;
        background: transparent;
      }
      li {
        list-style: none;
        margin: 0px 5px;
      }
      button {
        @include btn;
        @include bold;
        @include transition;
        font-size: 16px;
        padding: 15px 10px;
        white-space: nowrap;
        color: rgba($dark, 0.3);
        cursor: pointer;
        @media only screen and (min-width: $md-screen) {
          min-width: 0px;
          margin: 0px 20px 0px 0px;
          padding: 0px 0px;
          color: rgba($dark, 0.3);
          @include title-2;
          font-size: 18px;
        }
        @media only screen and (min-width: 1150px) {
          font-size: 20px;
        }
        &.active {
          color: $dark;
          @media only screen and (min-width: $md-screen) {
            color: $dark;
            &:after {
              height: 2px;
              margin-top: 5px;
              display: block;
              width: auto;
              background: $primary-color;
              content: '';
            }
          }
        }
        &:hover {
          color: rgba($dark, 0.7);
        }
      }
    }
  }
  .content {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    overflow: hidden;
    height: calc(100vh - 168px);
    @media only screen and (min-width: $md-screen) {
      height: calc(100vh - 130px);
    }
    .categories {
      display: flex;
      width: calc(100% * 6);
      height: 100%;
      transition: all 0.6s ease-in-out;
      @media only screen and (min-width: $md-screen) {
        width: calc((100vw - 200px) * 9);
      }
      .category {
        width: 100vw;
        height: 100%;
        overflow-y: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        // @include scrollbar;
        z-index: 3;
        @media only screen and (min-width: $md-screen) {
          width: calc(100vw - 300px);
        }
      }
    }
  }
}

.actus {
  display: none;
  height: 100vh;
  width: 300px;
  background-color: $white;
  @media only screen and (min-width: $md-screen) {
    display: block;
    position: absolute;
    top: 0px;
    padding: 90px 0px $margin-md $margin-md;
  }
  .news-container {
    overflow: auto;
    padding: 0px $margin-md 0px 0px;
    height: calc(100% - 0px);
    @include scrollbar;
    .news {
      font-size: 14px;
      border-bottom: 1px solid rgba($dark, 0.3);
      margin-bottom: 10px;
      padding-bottom: 10px;
      p {
        margin: 5px 0px;
      }
      button {
        @include btn;
        @include text;
        @include bold;
        padding: 0px;
        margin: 5px 0px;
        &:hover {
          color: $primary-color;
        }
      }
      .infos {
        display: flex;
        justify-content: space-between;
        color: rgba($dark, 0.3);
        font-style: italic;
      }
    }
  }
}

.news-modal {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
  background-color: $bg-color;
  @include scrollbar;
  @media only screen and (min-width: $md-screen) {
    width: 80vw;
    min-height: 0vh;
    max-height: 90vh;
    border-radius: 12px;
  }
  img {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
  }
  button {
    @include btn;
    position: absolute;
    right: 20px;
    top: 20px;
    background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    &:hover {
      background-color: rgba($dark, 0.2);
    }
  }
  .content {
    padding: 10px 20px;
    @media only screen and (min-width: $md-screen) {
      padding: 20px 40px 40px;
    }
    h1 {
      margin: 0px;
      @include bold;
    }
    .infos {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 10px;
      color: rgba($dark, 0.3);
      font-style: italic;
      p {
        margin-right: 20px;
        &:not(:first-child) {
          border-left: 1px solid rgba($dark, 0.3);
          padding-left: 20px;
        }
      }
    }
  } 
}