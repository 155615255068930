@import '../../styles/variables';

.container {
  background-color: $white;
  padding: 30px;
  max-width: 400px;
  border-radius: 4px;
  .logo {
    display: flex;
    justify-content: center;
  }
  h4 {
    text-align: center;
  }
  .submit {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    .btn {
      @include btn-primary
    }
  }
}