@import "../../styles/variables.scss";

@mixin inline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  label {
    margin-right: 10px;
    margin-top: 0px;
  }
}

@mixin label {
  @include text;
  font-size: 13px;
  color: $primary-color;
}

@mixin input {
  margin-top: 5px;
  display: block;
  @include transition;
  padding: 6px 12px;
  border: none;
  outline: none;
  border-radius: 16px;
  font-size: 13px;
  width: 100%;
  min-width: 100%;
  background-color: rgba($primary-color, 0.1);
  border: 1px solid rgba($primary-color, 0.1);
  box-sizing: border-box;
  @include text;
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    box-shadow: 0 0 0 90px white inset !important;
    background: transparent;
  }

  &:focus {
    border: 1px solid rgba($primary-color, 0.8);
  }

  &::placeholder {
    font-size: 13px;
    color: rgba($primary-color, 0.4);
  }
}

@mixin primary {
  .input {
    @include input;
    border-radius: $radius;
    min-height: 50px;
    background-color: rgba($white, 0.2);
    border: 2px solid #ececf0;
    color: $black;
    @include label;
  }
  label {
    color: $black;
  }
}

