@import '../../../styles/variables.scss';
@import '../_form-mixin.scss';

.container-input-email{

  label{
    @include label;
  }

  .input {
    @include input;
  }

  &.inline {
    @include inline;
  }

  &.primary {
    @include primary;
  }
  
}
