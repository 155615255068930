@import '../../styles/variables';

.container {
  @include transition;
  @include btn;
  @include text;
  text-decoration: none;
  text-align: left;
  color: $dark;
  padding: 10px;
  border-radius:  $radius-lg;
  background-color: rgba($dark, 0.05);
  display: flex;
  margin: 0px 20px 20px 0px;
  &:hover {
    background-color: rgba($dark, 0.1);
  }
  .link {
    @include btn;
    @include text;
    display: flex;
    text-align: left;
    width: calc(100% - 80px);
    .logo {
      margin-right: 15px;
      @media only screen and (min-width: $sm-screen) {
        margin-right: 20px;
      }
      img {
        border-radius: $radius-lg;
        width: 60px;
        height: 60px;
        object-fit: cover;
        @media only screen and (min-width: $sm-screen) {
          width: 106px;
          height: 106px;
        }
      }
    }
    h3 {
      @include title-2;
      padding: 0px;
      font-size: 20px;
      margin: 0px;
      display: block;
    }
    .infos {
      width: calc(100% - 20px);
      @media only screen and (min-width: $sm-screen) {
        width: calc(100% - 170px);
      }
      p {
        max-width: 600px;
        margin: 5px 0px 5px 0px;
      }
      a {
        @include bold;
        color: $primary-color;
      }
    }
  }
  .star {
    width: 80px;
    display: flex;
    align-items: flex-start;
    @media only screen and (min-width: $sm-screen) {
      justify-content: center;
      align-items: center;
    }
    button {
      @include btn;
      padding: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 22px;
      color: rgba($primary-color, 0.2);
      @media only screen and (min-width: $sm-screen) {
        font-size: 32px;
      }
      &.is-favorite {
        color: $primary-color;
      }
      &:hover {
        color: rgba($primary-color, 0.5);
      }
    }
  }
}