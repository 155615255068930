@import '../../styles/variables';

.container {
  width: 100%;
  height: 100vh;
  padding: 0px $margin-md;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;
  padding-top: 80px;
  @media only screen and (min-width: $sm-screen) {
    padding-top: 20px;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  &.is-notifs {
    pointer-events: none;
  }
  .col {
    position: relative;
    @media only screen and (min-width: $sm-screen) {
      width: calc(50% - 10px);
      &:last-child {
        z-index: 0;
      } 
    }
    h1 {
      @include title-1;
      margin: 0px;
      .note {
        font-size: 18px;
      }
    }
    h2 {
      @include title-1;
      font-size: 22px;
      margin: 0px;
      .note {
        font-size: 18px;
      }
    }
    .code {
      display: none;
      @media only screen and (min-width: $sm-screen) {
        display: block;
      }
      width: 100%;
      text-align: right;
      color: rgba($dark, 0.5)
    }
    .text {
      @include text;
    }
  
    header {
      // background: linear-gradient(to bottom, $bg-color 80%, transparent 100%);
      background-color: $bg-color;
      z-index: 1;
      top: 0px;
      left: 0px;
      position: fixed;
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: $margin-md;
      @media only screen and (min-width: $sm-screen) {
        position: relative;
        padding: 0px 0px $margin-md 0px;
      }
      a {
        text-decoration: none;
      }
      .back {
        @include btn;
        display: flex;
        align-items: center;
        top: 20px;
        height: 40px;
        z-index: 2;
        @include bold;
        font-size: 16px;
        color: #535353;
        svg {
          margin-right: 10px ;
        }
        &:hover {
          color: $primary-color;
        }
      }
    } 
    .infos {
      display: flex;
      .logo {
        width: 60px;
        @media only screen and (min-width: $sm-screen) {
          width: 120px;
        }
        img {
          border-radius: $radius-lg;
          width: 50px;
          height: 50px;
          object-fit: cover;
          @media only screen and (min-width: $sm-screen) {
            width: 106px;
            height: 106px;
          }
        }
      }
      .title {
        position: relative;
        width: calc(100% - 60px);
        @media only screen and (min-width: $sm-screen) {
          width: calc(100% - 130px);
        }
        a {
          @include bold;
          color: $dark;
          font-size: 16px;
          max-width: 130px;
          overflow: hidden;
          display: block;
          @media only screen and (min-width: $xs-screen) {
            position: absolute;
            top: 0px;
            right: 0px;
          }
        }
        h1 {
          @include title-1;
          margin: 0px;
          text-transform: uppercase;
        }
      }
    }
    .box {
      margin-top: 20px;
      @include box;
      .file-list {
        a {
          text-decoration: none;
        }
        .file {
          @include transition;
          display: flex;
          align-items: center;
          color: $dark;
          border-radius: $radius;
          &:hover {
            background-color: rgba($dark, 0.1);
          }
          p {
            @include bold;
            margin-left: 20px;
          }
          img {
            margin-top: 1px;
            width: 20px;
            margin-left: 10px;
          }
        }
      }
      &.good {
        background-color: rgba($primary-color ,0.1);
        color: $primary-color;
        margin-bottom: 20px;
      }
      &.contact {
        background-color: $primary-color;
        color: $white;
        .contact-text {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          .text {
            width: calc(100% - 120px);
          }
          a {
            text-decoration: none;
          }
          button {
            @include btn-primary;
          }
        }
      }
    }
    .illu {
      display: none;
      height: 300px;
      margin: 20px 0px;
      @media only screen and (min-width: $sm-screen) {
        display: flex;
        height: 70vh;
        margin: 0px;
      }
      img {
        border-radius: $radius-lg;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &.mobile {
        display: flex;
        width: calc(100% + 40px);
        transform: translate(-20px);
        margin: 0px 0px $margin-md 0px;
        img {
          border-radius: 0px;
        }
        @media only screen and (min-width: $sm-screen) {
          display: none;
        }
      }
    }
  }
}