@import '../../styles/variables';

.container {
  width: 100%;
  height: 100%;
  padding: 0px $margin-md;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;
  padding-top: 100px;
  // &.is-notifs {
  //   pointer-events: none;
  // }
  header {
    // background: linear-gradient(to bottom, $bg-color 80%, transparent 100%);
    background-color: $bg-color;
    z-index: 1;
    top: 0px;
    left: 0px;
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: $margin-md;
    @media only screen and (min-width: $md-screen) {
      width: calc(100% - 14px);
    }
    .logo {
      width: 234px;
      margin-right: 20px;
      img {
        width: 100%;
      }
    }
  }
  h1 {
    @include title-2;
    font-size: 24px;
    margin: 2px 0px;
  }
  h2 {
    @include title-2;
  }
  .list {
    @include card-list;
  }
}
