@mixin title-1 {
  font-family: "Ubuntu-Bold";
  font-weight: 700;
  font-size: 28px;
}

@mixin title-2 {
  font-family: "Ubuntu-Bold";
  font-weight: 700;
  font-size: 18px;
}

@mixin title-3 {
  font-family: "Ubuntu-Bold";
  font-weight: 700;
  font-size: 16px;
}

@mixin text {
	font-family: "Ubuntu-Regular";
  font-weight: 400;
  font-size: 14px;
}

@mixin legal {
	font-family: "Roboto-Regular";
  font-weight: 400;
  font-size: 12px;
}

@mixin bold {
  font-weight: 700;
}

@mixin transition {
  transition: all 0.2s ease-in-out;
}

@mixin btn {
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	background-color: transparent;
  outline: none;
  border: none;
}

@mixin form-group {
  margin-bottom: 20px;
}

@mixin btn-primary {
  @include btn;
  @include title-3;
  min-height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $primary-color;
  padding: 0px 12px;
  border-radius: 16px;
  color: $primary-color;
  text-decoration: none;
  &:hover {
    background-color: $primary-color;
    color: $white;
    p {
      color: $white;
    }
  }
  &.white {
    border: 1px solid $white;
    background-color: $primary-color;
    color: $white;
    &:hover {
      background-color: rgba($white, 0.2);
    }
    p {
      color: $white;
    }
  }
  p {
    margin: 0px;
    text-decoration: none;
    @include transition;
  }
  svg {
    margin-left: 0px;
  }
}

@mixin card-list {
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-gap: 10px;
  margin-bottom: 20px;
  @media only screen and (min-width: $sm-screen) {
    grid-template-columns: repeat(3,1fr);
  }
  @media only screen and (min-width: $md-screen) {
    grid-template-columns: repeat(4,1fr);
  }
  @media only screen and (min-width: $lg-screen) {
    grid-template-columns: repeat(5,1fr);
  }
}

@mixin box {
  background: rgba($dark, 0.05);
  border-radius: 10px;
  padding: 20px;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba($primary-color, 0.2);
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba($primary-color, 0.5);
  }
}
