@import '../../styles/variables';

.container {
  width: 100%;
  height: 100vh;
  position: relative;
  &.is-notifs {
    pointer-events: none;
  }
  .row {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
    overflow: auto;
    @media only screen and (min-width: $sm-screen) {
      padding-top: 20px;
      top: 0px;
      position: absolute;
      width: 100vw;
      flex-direction: row;
      justify-content: space-between;
    }
  }
  header {
    // background: linear-gradient(to bottom, $bg-color 80%, transparent 100%);
    z-index: 1;
    top: 0px;
    left: 0px;
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: $bg-color;
    @media only screen and (min-width: $sm-screen) {
      position: relative;
      background-color: transparent;
    }
    .logo {
      width: 258px;
      z-index: 12;
      margin: 20px;
      img {
        width: 100%;
      }
    }
    a {
      text-decoration: none;
    }
 
  }
  .col {
    position: relative;
    @media only screen and (min-width: $sm-screen) {
      width: calc(50% - 10px);
      &:last-child {
        z-index: 0;
      } 
    }
    &.form {
      padding-top: 80px;
      @media only screen and (min-width: $sm-screen) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
    .back {
      @include btn;
      display: flex;
      align-items: center;
      top: 20px;
      height: 40px;
      z-index: 2;
      @include bold;
      font-size: 16px;
      color: #535353;
      svg {
        margin-right: 10px ;
      }
      &:hover {
        color: $primary-color;
      }
    }
    h1 {
      @include title-1;
      margin: 0px;
    }
    h2 {
      @include title-1;
      font-size: 22px;
      margin: 0px;
    }
    .text {
      @include text;
    }

    .legal {
      @include legal;
      color: rgba($dark, 0.6);
      a {
        color: rgba($dark, 0.6);
      }
      @media only screen and (min-width: $sm-screen) {
        width: 100%;
        bottom: 0px;
        padding: 0px;
      }
    }
    form {
      @media only screen and (min-width: $md-screen) {
        padding: 40px;
      }
      .name {
        @media only screen and (min-width: $sm-screen) {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .form-group {
            width: calc(50% - 10px);
          }
        }
      }
      .form-group {
        width: 100%;
        @include form-group;
      }
      .submit {
        margin: 40px 0px;
        @include btn-primary;
        p {
          margin: 0px;
          padding-left: 10px;
        }
      }
      .message {
        color: $success;
      }
    }
    .infos {
      display: none;
      position: absolute;
      top: 0px;
      padding: $margin-md;
      margin: $margin-md;
      background-color: $bg-color;
      border-radius: $radius-lg;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      @media only screen and (min-width: $sm-screen) {
        display: flex;
      }
      .logo {
        width: 60px;
        @media only screen and (min-width: $sm-screen) {
          width: 120px;
        }
        img {
          border-radius: $radius-lg;
          width: 50px;
          height: 50px;
          object-fit: cover;
          @media only screen and (min-width: $sm-screen) {
            width: 106px;
            height: 106px;
          }
        }
      }
      .title {
        position: relative;
        width: calc(100% - 60px);
        @media only screen and (min-width: $sm-screen) {
          width: calc(100% - 130px);
        }
        a {
          @include bold;
          color: $dark;
          font-size: 16px;
          max-width: 130px;
          overflow: hidden;
          display: block;
          @media only screen and (min-width: $xs-screen) {
            position: absolute;
            top: 0px;
            right: 0px;
          }
        }
        h1 {
          @include title-1;
          margin: 0px;
          text-transform: uppercase;
        }
      }
    }
    .illu {
      display: none;
      height: 300px;
      @media only screen and (min-width: $sm-screen) {
        display: flex;
        height: calc(100vh - 40px);
        margin: 0px;
      }
      img {
        border-radius: $radius-lg;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &.mobile {
        display: flex;
        width: calc(100% + 40px);
        transform: translate(-20px);
        margin: 0px 0px $margin-md 0px;
        img {
          border-radius: 0px;
        }
        @media only screen and (min-width: $sm-screen) {
          display: none;
        }
      }
    }
  }
  
}