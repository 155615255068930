@import '../../styles/variables';

.container {
  width: 100%;
  height: 100vh;
  position: fixed;
  display: block;
  left: 0px;
  top:0px;
  z-index: -0;
  @include transition;
  pointer-events: none;
  background-color: rgba($bg-color, 0);
  opacity: 0;
  &.active {
    z-index: 10;
    pointer-events: all;
    background-color: $bg-color;
    opacity: 1;
  }
  .header {
    position: relative;
    width: 100%;
    height: 100px;
    background-color: $bg-color;
  }
  .notifs {
    width: 100%;
    height: calc(100% - 100px);
    overflow: auto;
    .notif {
      @include box;
      position: relative;
      width: calc(100% - 40px);
      margin: $margin-md auto;
      padding: 10px;
      display: flex;
      @media only screen and (min-width: $sm-screen) {
        width: 80%;
      }
      .logo {
        margin-right: 15px;
        @media only screen and (min-width: $sm-screen) {
          margin-right: 20px;
        }
        img {
          border-radius: $radius-lg;
          width: 40px;
          height: 40px;
          object-fit: cover;
          @media only screen and (min-width: $sm-screen) {
            width: 60px;
            height: 60px;
          }
        }
      }
      h3 {
        @include title-2;
        padding: 0px;
        font-size: 18px;
        margin: 0px;
        display: block;
      }
      .infos {
        width: 100%;
        margin-bottom: 20px;
        @media only screen and (min-width: $sm-screen) {
          width: calc(100% - 170px);
          margin-bottom: 0px;
        }
        p {
          max-width: 600px;
          margin: 5px 0px 5px 0px;
        }
        a {
          @include bold;
          color: $primary-color;
        }
      }
      .date {
        position: absolute;
        bottom: 0px;
        right: 0px;
        @media only screen and (min-width: $sm-screen) {
          position: relative;
        }
        p {
          color: rgba($dark ,0.5);
          margin: 10px;
          @media only screen and (min-width: $sm-screen) {
            margin: 5px;
          }
        } 
      }
      // .star {
      //   display: flex;
      //   align-items: flex-start;
      //   @media only screen and (min-width: $sm-screen) {
      //     align-items: center;
      //   }
      //   button {
      //     @include btn;
      //     padding: 0px;
      //     display: flex;
      //     align-items: center;
      //     justify-content: center;
      //     font-size: 22px;
      //     color: rgba($primary-color, 0.2);
      //     @media only screen and (min-width: $sm-screen) {
      //       font-size: 32px;
      //     }
      //     &.is-favorite {
      //       color: $primary-color;
      //     }
      //     &:hover {
      //       color: rgba($primary-color, 0.5);
      //     }
      //   }
      // }
    }
  }
}