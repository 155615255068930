@import '../../styles/variables';

.container {
  @include transition;
  @include btn;
  @include text;
  padding: 0px;
  text-decoration: none;
  text-align: left;
  z-index: 0;
  position: relative;
  height: 260px;
  max-width: 99%;
  border: 1px solid #001989;
  box-sizing: border-box;
  border-radius: 5px;
  overflow: hidden;
  @media only screen and (min-width: $sm-screen) {
    height: 300px;
  }
  .tool-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .tool {
    position: absolute;
    background: rgba(0, 25, 137, 0.7);
    bottom: 0px;
    color: $white;
    width: 100%;
    padding: 8px;
    text-transform: uppercase;
    @media only screen and (min-width: $sm-screen) {
      padding: 12px;
    }
    p {
      margin: 0px;
      font-size: 12px;
    }
    h2 {
      margin: 5px 0px 0px 0px;
      padding: 0px;
      @include bold;
      font-size: 18px;
      display: block;
    }
  }
  .icon {
    position: absolute;
    bottom: 6px;
    right:  8px;
  }
}