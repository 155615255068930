@import '../../styles/variables';

.user {
  position: relative;
  z-index: 12;
  display: flex;
  pointer-events: all;
  @media only screen and (min-width: $md-screen) {
    align-items: center;
  }
  p {
    display: none;
    @media only screen and (min-width: $md-screen) {
      display: block;
      @include text;
      color: #666666;
      margin-right: 10px;
    }
  }
  .notif {
    @include btn;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #97a3da;
    position: absolute;
    right: 35px;
    top: 5px;
    width: 30px;
    height: 30px;
    border-radius: 20px;
    margin-right: 8px;
    @media only screen and (min-width: $md-screen) {
      position: relative;
      margin-right: 4px;
      right: 0px;
      top: 0px;
    }
    &.new {
      color: $primary-color;
    }
    &:hover {
      background-color: #e0e0e0;
    }
  }
  .logout {
    @include btn;
    @include text;
    @include bold;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: #C4C4C4;
    color: #666666;
  }
}