@import '../../styles/variables';

.container {
  @include btn;
  @include transition;
  @include text;
  text-decoration: none;
  color: $dark;
  padding: 10px;
  border-radius:  $radius-lg;
  background-color: rgba($dark, 0.05);
  display: flex;
  justify-content: flex-start;
  text-align: center;
  margin: 0px 20px 20px 0px;
  &:hover {
    background-color: rgba($dark, 0.1);
  }
  .logo {
    margin-right: 15px;
    @media only screen and (min-width: $sm-screen) {
      margin-right: 20px;
    }
    img {
      border-radius: $radius-lg;
      width: 80px;
      height: 80px;
      object-fit: cover;
    }
  }
  h3 {
    @include title-2;
    padding: 0px;
    font-size: 20px;
    margin: 0px;
    display: block;
  }
  .infos {
    width: calc(100% - 20px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: center;
    @media only screen and (min-width: $sm-screen) {
      width: calc(100% - 170px);
    }
    p {
      max-width: 600px;
      margin: 5px 0px 5px 0px;
    }
    a {
      @include bold;
      color: $primary-color;
    }
  }
  .star {
    display: flex;
    align-items: flex-start;
    @media only screen and (min-width: $sm-screen) {
      align-items: center;
    }
    button {
      @include btn;
      padding: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 22px;
      color: rgba($primary-color, 0.2);
      @media only screen and (min-width: $sm-screen) {
        font-size: 32px;
      }
      &.is-favorite {
        color: $primary-color;
      }
      &:hover {
        color: rgba($primary-color, 0.5);
      }
    }
  }
}