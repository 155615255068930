@import '../../styles/variables';

.overlay-modal{
  position: fixed;
  z-index: 999;
  top:0;
  height: 100%;
  width: 100%;
  // pointer-events: none;
  background-color: rgba(3, 18, 83, 0.62);
  &.click-out {
    pointer-events: all;
  }
}

.modal{
  position: fixed;
  z-index: 1000;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);

  .close{
    position: absolute;
    top: -16px; 
    right: -16px;
    background-color: $primary-color;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    border-radius: 16px;
    cursor: pointer;
  }
}
