@import './mixins.scss';

// $bg-color: #FAFBFB;
$bg-color: #f7f7f7;
$white: #FFFFFF;
$black: #000000;
$dark: #000000;

$primary-color: #001989;
$secondary-color: #3E9F2C;

$success: #4BA43A;
$danger : red;

$shadow: 0px 2px 21px 2px rgba(24, 24, 68, 0.0669799);

$lg-screen: 1200px;
$md-screen: 1024px;
$sm-screen: 768px;
$xs-screen: 576px;

$margin-md : 20px;
$margin-sm : 15px;
$margin-xs : 10px;

$regular: 14px;

$radius: 5px;
$radius-lg: 10px;

@font-face {
	font-family: "Ubuntu-Bold";
	src: url("../assets/fonts/Ubuntu-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "Ubuntu-Regular";
	src: url("../assets/fonts/Ubuntu-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "Roboto-Regular";
	src: url("../assets/fonts/Roboto-Regular.ttf") format("truetype");
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
  box-shadow: 0 0 0 30px transparent inset !important;
}